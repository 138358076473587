import React, { useState, useEffect } from 'react';
import { bool, func, object, number, string } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage, intlShape } from '../../../../util/reactIntl';
import { ACCOUNT_SETTINGS_PAGES } from '../../../../routing/routeConfiguration';
import { propTypes } from '../../../../util/types';
import {
  Avatar,
  InlineTextButton,
  LinkedLogo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
} from '../../../../components';

import TopbarSearchForm from '../TopbarSearchForm/TopbarSearchForm';
import CustomLinksMenu from './CustomLinksMenu/CustomLinksMenu';

import css from './TopbarDesktop.module.css';
import CategoriesDropdownMenu from './CategoriesDropdownMenu';
import IconCard from '../../../../components/SavedCardDetails/IconCard/IconCard';
import { BUYER } from '../../../../util/constants';
import { ensureUser } from '../../../../util/data';

const BABY_GENDER = "Baby";
const GIRL_GENDER = "Girls";
const BOY_GENDER = "Boys";
const SEARCH = "/search";

const SignupLink = () => {
  return (
    <NamedLink name="SignupPage" className={css.topbarLink}>
      <span className={css.topbarLinkLabel}>
        <FormattedMessage id="TopbarDesktop.signup" />
      </span>
    </NamedLink>
  );
};

const LoginLink = () => {
  return (
    <NamedLink name="LoginPage" className={css.topbarLink}>
      <span className={css.topbarLinkLabel}>
        <FormattedMessage id="TopbarDesktop.login" />
      </span>
    </NamedLink>
  );
};

const InboxLink = ({ notificationCount, currentUserHasListings }) => {
  const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;
  return (
    <NamedLink
      className={css.topbarLink}
      name="InboxPage"
      params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
    >
      <span className={css.topbarLinkLabel}>
        <FormattedMessage id="TopbarDesktop.inbox" />
        {notificationDot}
      </span>
    </NamedLink>
  );
};

const ProfileMenuUniversal = ({ currentPage, currentUser, onLogout, currentUserHasListings, onHandleMenuClick, notificationCount }) => {
  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  return (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen} onHandleMenuClick={onHandleMenuClick}>
        <IconCard brand={'sideLine'} />
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        <MenuItem key="SearchPage">
          <NamedLink
            className={classNames(css.menuLink, currentPageClass('aboutPage'))}
            name="AlgoliaSearchPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id='TopbarDesktop.showNowLabel' />
          </NamedLink>
        </MenuItem>
        <MenuItem key="newListingPage">
          <NamedLink
            className={classNames(css.menuLink, currentPageClass('NewListingPage'))}
            name="NewListingPage"
          >
            <FormattedMessage id="TopbarDesktop.createListingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="ManageListingsPage">
          <NamedLink
            className={classNames(css.menuLink, currentPageClass('ManageListingsPage'))}
            name="ManageListingsPage"
          >
            <FormattedMessage id="TopbarDesktop.yourListingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="InboxBasePage">
          <NamedLink
            className={classNames(css.menuLink, currentPageClass('InboxBasePage'))}
            name="InboxBasePage"
            params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
          >
            <FormattedMessage id='TopbarDesktop.InboxLinkText' values={{ count: notificationCount || 0 }} />
          </NamedLink>
        </MenuItem>
        <MenuItem key="ProfileSettingsPage">
          <NamedLink
            className={classNames(css.menuLink, currentPageClass('ProfileSettingsPage'))}
            name="ProfileSettingsPage"
          >
            <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.menuLink} onClick={onLogout}>
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  );
};

const LoginMenu = ({ currentPage, currentUser, onLogout }) => {
  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  return (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <IconCard brand={'sideLine'} />
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        <MenuItem key="SearchPage">
          <NamedLink
            className={classNames(css.menuLink, currentPageClass('aboutPage'))}
            name="AlgoliaSearchPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id='TopbarDesktop.showNowLabel' />
          </NamedLink>
        </MenuItem>
        <MenuItem key="helpCenterPage">
          <NamedLink
            className={classNames(css.menuLink, currentPageClass('aboutPage'))}
            name="SignupPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id='TopbarDesktop.sellNowLabel' />
          </NamedLink>
        </MenuItem>
        <MenuItem key="signUpPage">
          <NamedLink
            className={classNames(css.menuLink, currentPageClass('aboutPage'))}
            name="SignupPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id='TopbarDesktop.signupLabel' />
          </NamedLink>
        </MenuItem>
        <MenuItem key="loginPage">
          <NamedLink
            className={classNames(css.menuLink, currentPageClass('aboutPage'))}
            name="LoginPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id='TopbarDesktop.loginLabel' />
          </NamedLink>
        </MenuItem>
      </MenuContent>
    </Menu>
  );
};

const TopbarDesktop = props => {
  const {
    className,
    config,
    customLinks,
    currentUser,
    currentPage,
    rootClassName,
    currentUserHasListings,
    notificationCount,
    intl,
    isAuthenticated,
    onLogout,
    onSearchSubmit,
    initialSearchFormValues,
    onHandleMenuClick,
    redirect
  } = props;

  const [mounted, setMounted] = useState(false);
  const [openSearchForm, setOpenSearchForm] = useState(false);
  const pathName = redirect?.location?.pathname;
  const isSearchPage = pathName == SEARCH;

  useEffect(() => {
    setMounted(true);
  }, []);

  const marketplaceName = config.marketplaceName;
  const listingCategories = config?.categoryConfiguration?.categories;
  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;

  const giveSpaceForSearch = customLinks == null || customLinks?.length === 0;
  const classes = classNames(rootClassName || (isSearchPage ? css.rootWithoutHeight : css.root), className);

  const inboxLinkMaybe = authenticatedOnClientSide ? (
    <InboxLink
      notificationCount={notificationCount}
      currentUserHasListings={currentUserHasListings}
    />
  ) : null;

  const profileMenuMaybe = authenticatedOnClientSide ?
    (
      <ProfileMenuUniversal
        onHandleMenuClick={onHandleMenuClick}
        currentPage={currentPage}
        currentUser={currentUser}
        onLogout={onLogout}
        currentUserHasListings={currentUserHasListings}
        notificationCount={notificationCount}
      />
    ) : (
      <LoginMenu currentPage={currentPage} currentUser={currentUser} onLogout={onLogout} />
    );

  const signupLinkMaybe = isAuthenticatedOrJustHydrated ? null : <SignupLink />;
  const loginLinkMaybe = isAuthenticatedOrJustHydrated ? null : <LoginLink />;

  const sellingMainCategory = ['Girls', 'Boys', 'Baby'];

  const handleCartClick = () => {
    if (authenticatedOnClientSide) {
      redirect.push('/user/cart');
    } else {
      redirect.push('/signup');
    }
  };

  const ensuredUser = ensureUser(currentUser);
  const cartData = (ensuredUser && ensuredUser.id && ensuredUser?.attributes?.profile?.publicData?.cartData) ?? [];
  const carthasItemsMaybe = cartData && cartData.length && cartData.reduce((acc, curr) => {
    return acc += curr.quantity;
  }, 0);

  const handleGenderSearch = value => {
    if (value == BABY_GENDER) {
      redirect.push(`/search?refinementList%5BpublicData.isBabyCategory%5D%5B0%5D=true`);
    } else if (value == GIRL_GENDER) {
      redirect.push(`/search?refinementList%5BpublicData.gender.label%5D%5B0%5D=Girl`);
    } else {
      redirect.push(`/search?refinementList%5BpublicData.gender.label%5D%5B0%5D=Boy`);
    }
  };

  return (
    <nav className={classes}>
      <div className={css.headerTopItems}>

        {/* Main Logo */}
        <div className={css.logoWrapper}>
          <LinkedLogo
            className={css.logoLink}
            layout="desktop"
            alt={intl.formatMessage({ id: 'TopbarDesktop.logo' }, { marketplaceName })}
            linkToExternalSite={config?.topbar?.logoLink}
          />
        </div>

        <div className={css.menuWrappers}>

          {/* Search Form Default (Keywords) */}

          {/* Search Icon */}
          <div className={css.searchBtnWrapper}>
            {openSearchForm ? <TopbarSearchForm
              className={classNames(css.searchLink, { [css.takeAvailableSpace]: giveSpaceForSearch })}
              desktopInputRoot={css.topbarSearchWithLeftPadding}
              onSubmit={onSearchSubmit}
              initialValues={initialSearchFormValues}
              appConfig={config}
            /> : null}
            <span onClick={() => {
              setOpenSearchForm(!openSearchForm);
              typeof window !== 'undefined' && window.history.pushState({}, "", "/search");
              onSearchSubmit();
            }} className={css.iconWrapper}>
              {openSearchForm ? <IconCard brand={'cross'} key={'cross-icon'} /> : <IconCard brand={'search'} key={'search-icon'} />}
            </span>
          </div>

          {/* Cart Icon and Number */}
          <div className={css.cartWrapper} onClick={handleCartClick}>
            <IconCard brand={"cart"} key={'cart-icon'} />
            {carthasItemsMaybe > 0 ? <span className={css.count}>{carthasItemsMaybe}</span> : null}
          </div>

          {/* Profile-Menu Maybe */}
          {profileMenuMaybe}

        </div>
      </div>

      {!isSearchPage ? <div className={css.menuLinks}>
        {sellingMainCategory.map(cate => {
          return (
            <div className={css.categoryLink} key={cate}>
              <span onClick={() => handleGenderSearch(cate)}>
                {cate}
              </span>
              <div className={css.dropDownMenu}>
                <CategoriesDropdownMenu type={cate} listingCategories={listingCategories} redirect={redirect} />
              </div>
            </div>
          )
        })}

        <div>
          <FormattedMessage id='SectionDetailsMaybe.iconStanding' />
        </div>

        <NamedLink name="AlgoliaSearchPage" className={css.link}>
          <FormattedMessage id='TopbarDesktop.newInLabel' />
        </NamedLink>
        <div className={css.categoryLink} key={"Brands"}>
          <FormattedMessage id='TopbarDesktop.brandsLabel' />
          <div className={css.dropDownMenu}>
            <CategoriesDropdownMenu type={"Brands"} listingCategories={listingCategories} redirect={redirect} currentUser={currentUser} />
          </div>
        </div>
      </div> : null}
    </nav>
  );
};

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  config: null,
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
  config: object,
};

export default TopbarDesktop;
